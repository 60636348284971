import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Button,
    Modal,
    Box,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Container,
} from "@mui/material";
import styled from "styled-components";
import { Active, Inactive } from "../constants"; // Adjust path as necessary
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const PoolBody = ({ pool, walletAddress, index, fetchPools }: any) => {
    const [poolToDelete, setPoolToDelete] = useState({
        name: "",
        contract_address: "",
    });
    const [openPoolDialog, setOpenPoolDialog] = useState(false);
    const [blockNumber, setBlockNumber] = useState(null);
    const handleOpenPoolDialog = (pooladdress: string, poolname: string) => {
        setPoolToDelete({
            name: poolname,
            contract_address: pooladdress,
        });
        setOpenPoolDialog(true);
    };

    const handleClosePoolDialog = () => {
        setOpenPoolDialog(false);
    };
    const updatePoolStatus = (
        contractAddress: string,
        poolName: string,
        action: string
    ) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/update-pool-status`, {
                walletAddress,
                poolName,
                contractAddress,
                action,
            })
            .then(() => {
                fetchPools();
                console.log("Pool status updated");
            })
            .catch((error) => {
                console.error("Error updating pool status:", error);
            });
    };
    const handlePoolDelete = () => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/delete-pool`, {
                params: {
                    contractAddress: poolToDelete.contract_address,
                    poolName: poolToDelete.name,
                    walletAddress: walletAddress,
                },
            })
            .then((response) => {
                fetchPools(); // Assuming this function refreshes the list of pools
                setOpenPoolDialog(false); // Assuming this function closes a dialog
                console.log("Response after delete:", response);
            })
            .catch((error) => {
                console.error("Error while deleting pool:", error);
            });
    };
    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/fetchBlock`,
                {
                    query: `{
              _meta {
                  block {
                      number
                  }
              }
          }`,
                    poolName: pool.name,
                    variables: {},
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                console.log(response);
                setBlockNumber(response.data.blockNumber);
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
            });
    }, [pool]);

    return (
        <>
            <TableRow key={pool.contract_address}>
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="left">{pool.name}</TableCell>
                <TableCell align="left">{pool.contract_address}</TableCell>
                <TableCell align="left">{pool.start_block}</TableCell>
                <TableCell align="left">{blockNumber}</TableCell>
                <TableCell align="left">
                    {pool.status === Active ? "Active" : "Inactive"}
                </TableCell>
                <TableCell align="left">
                    <Button
                        variant="contained"
                        color={pool.status === Active ? "error" : "primary"}
                        onClick={() =>
                            updatePoolStatus(
                                pool.contract_address,
                                pool.name,
                                pool.status === Active ? Inactive : Active
                            )
                        }
                    >
                        {pool.status === Active ? "Pause" : "Resume"}
                    </Button>
                </TableCell>
                <TableCell align="left">
                    <Button
                        variant="contained"
                        onClick={() =>
                            handleOpenPoolDialog(
                                pool.contract_address,
                                pool.name
                            )
                        }
                        color="error"
                    >
                        Delete
                    </Button>
                </TableCell>
            </TableRow>
            <Dialog
                open={openPoolDialog}
                onClose={handleClosePoolDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Pool? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePoolDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handlePoolDelete}
                        color="primary"
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
const PoolsTable = ({ walletAddress }: any) => {
    const [pools, setPools] = useState([]);

    const [poolmodal, setPoolmodal] = useState(false);
    const [poolDetails, setPoolDetails] = useState({
        name: "",
        address: "",
        startBlock: 0,
    });
    useEffect(() => {
        fetchPools();
    }, [walletAddress]);

    const fetchPools = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/list-pools`, {
                params: { walletAddress },
            })
            .then((response) => {
                setPools(response.data);
                console.log("Pools fetched successfully");
            })
            .catch((error) => {
                console.error("Error fetching pools:", error);
            });
    };

    const addPool = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/add-pool`, {
                name: poolDetails.name,
                address: poolDetails.address,
                startBlock: poolDetails.startBlock,
                walletAddress: walletAddress,
            })
            .then((response) => {
                console.log("Pool added successfully");
                fetchPools();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <Wrapper>
                <div className="heading">
                    <h2>Pools</h2>
                    <Button
                        variant="contained"
                        onClick={() => setPoolmodal(true)}
                    >
                        Add Pool
                    </Button>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">
                                    Contract Address
                                </TableCell>
                                <TableCell align="left">Start Block</TableCell>
                                <TableCell align="left">Sync Block</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Action</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pools?.map((pool: any, index: any) => (
                                <PoolBody
                                    pool={pool}
                                    walletAddress={walletAddress}
                                    index={index}
                                    fetchPools={fetchPools}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Wrapper>
            <Modal
                open={poolmodal}
                onClose={() => setPoolmodal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="pool-modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Create Pool
                    </Typography>
                    <TextField
                        fullWidth
                        label="Add Pool Name"
                        variant="outlined"
                        value={poolDetails.name}
                        onChange={(e) =>
                            setPoolDetails((prevState) => ({
                                ...prevState,
                                name: e.target.value,
                            }))
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Add Contract Address"
                        variant="outlined"
                        value={poolDetails.address}
                        onChange={(e) =>
                            setPoolDetails((prevState) => ({
                                ...prevState,
                                address: e.target.value,
                            }))
                        }
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Add Start Block"
                        variant="outlined"
                        type="number"
                        // value={poolDetails.startBlock}
                        onChange={(e) =>
                            setPoolDetails((prevState) => ({
                                ...prevState,
                                startBlock: Number(e.target.value),
                            }))
                        }
                        sx={{ mt: 2 }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            addPool();
                            setPoolmodal(false);
                            setPoolDetails({
                                name: "",
                                address: "",
                                startBlock: 0,
                            });
                        }}
                        sx={{ mt: 2 }}
                    >
                        Create Pool
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

const Wrapper = styled.div`
    background: #fff;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 26px;
    margin-bottom: 30px;
    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 20px;
    }
`;

export default PoolsTable;
