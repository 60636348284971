import React from "react";
import { Typography, Button } from "@mui/material";
import styled from "styled-components";

const LoginBox = ({ handleConnect }: any) => {
    return (
        <StyledBox>
            <Typography variant="h6">
                Connect your wallet to see the Dashboard.
            </Typography>
            <Button
                onClick={handleConnect}
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
            >
                Connect Wallet
            </Button>
        </StyledBox>
    );
};

const StyledBox = styled.div`
    background: #fff;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 26px;
`;

export default LoginBox;
