import React from "react";
import KeysTable from "./KeysTable";
import PoolsTable from "./PoolsTable";
import styled from "styled-components";
import { Typography, Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const AdminPanel = ({ walletAddress, open }: any) => {
    return (
        <AdminPortal>
            <Wrapper className="top">
                <div className="heading">
                    <Typography variant="h6">Admin Panel</Typography>
                    <div className="accountinfo" onClick={() => open()}>
                        {walletAddress?.slice(0, 12)}... <AccountCircleIcon />
                    </div>
                </div>
            </Wrapper>
            <KeysTable walletAddress={walletAddress} />
            <PoolsTable walletAddress={walletAddress} />
        </AdminPortal>
    );
};

const Wrapper = styled.div`
    background: #fff;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 26px;
    margin-bottom: 30px;
    .heading {
        padding: 0;
        border-radius: 10px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        // margin-bottom: 20px;
        h1 {
            font-size: 22px;
        }
        h2 {
            font-size: 19px;
        }
    }
    &.top {
        padding: 12px 20px;
        background: linear-gradient(to top, #5d6dc3, #3c86d8);
        color: #fff;

        .accountinfo {
            display: flex;
            align-items: center;
            .MuiSvgIcon-root {
                margin-left: 10px;
            }
        }
    }
`;

const AdminPortal = styled.div`
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    margin-top: 10px;
    // margin-bottom: 30px;
`;

export default AdminPanel;
