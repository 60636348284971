import React, { useState, useEffect } from "react";
import { Container, Typography, Button, LinearProgress } from "@mui/material";
import axios from "axios";
import useWeb3 from "../../hooks/useWeb3";
import AdminPanel from "../../components/AdminPanel";
import LoginBox from "../../components/LoginBox";
import styled from "styled-components";

function Dashboard() {
    const { open, disconnect, isConnected, address } = useWeb3();
    const [walletAddress, setWalletAddress] = useState<any>("");
    console.log("isConnected", isConnected);
    useEffect(() => {
        if (address) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/createUser`, {
                    walletAddress: address,
                })
                .then((response) => {
                    if (response.status === 201 || response.status === 202) {
                        setWalletAddress(address);
                    }
                })
                .catch((error) => {
                    // disconnect();
                    console.error("Error on creating user:", error);
                });
        } else {
            setWalletAddress("");
        }
    }, [address]);

    return (
        <Layout>
            {/* {`walletAddress${walletAddress}`} */}
            {/* {`isConnected${isConnected}`}
            {`address${address}`} */}
            {walletAddress ? (
                <AdminPanel walletAddress={walletAddress} open={open} />
            ) : (
                <Container
                    component="main"
                    maxWidth="xs"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh",
                    }}
                >
                    {isConnected ? (
                        <div>
                            <h4>Loading...</h4>
                            <LinearProgress />
                        </div>
                    ) : (
                        <LoginBox handleConnect={open} />
                    )}
                </Container>
            )}
        </Layout>
    );
}

const Layout = styled.div`
    min-height: 100vh;
`;

export default Dashboard;
