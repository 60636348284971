import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Button,
    Modal,
    Box,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Container,
} from "@mui/material";
import styled from "styled-components";
import { Delete, Revoke, Active } from "../constants"; // Adjust path as necessary
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const KeysTable = ({ walletAddress }: any) => {
    const [keys, setKeys] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [apiKeyToDelete, setApiKeyToDelete] = useState("");
    const [keymodal, setKeymodal] = useState(false);
    const [keyName, setKeyName] = useState("");

    useEffect(() => {
        fetchKeys();
    }, [walletAddress]);
    const handleKeyNameChange = (event: any) => setKeyName(event.target.value);
    const fetchKeys = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/list-keys`, {
                params: { walletAddress },
            })
            .then((response) => {
                setKeys(response.data);
                console.log("API Keys fetched successfully");
            })
            .catch((error) => {
                console.error("Error fetching API keys:", error);
            });
    };

    const updateKeyStatus = (apiKey: string, action: string) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/update-key-status`, {
                apiKey,
                action,
            })
            .then(() => {
                fetchKeys();
                console.log("Key status updated");
            })
            .catch((error) => {
                console.error("Error updating key status:", error);
            });
    };

    const handleOpenDialog = (apiKey: string) => {
        setApiKeyToDelete(apiKey);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleDelete = () => {
        updateKeyStatus(apiKeyToDelete, Delete);
        setOpenDialog(false);
    };
    const addKey = () => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/generate-key`, {
                name: keyName,
                walletAddress: walletAddress,
            })
            .then((response) => {
                console.log("Key Generated Successfully.");
                fetchKeys();
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <>
            <Wrapper>
                <div className="heading">
                    <h2>Keys</h2>
                    <Button
                        variant="contained"
                        onClick={() => setKeymodal(true)}
                    >
                        Add Key
                    </Button>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">API Key</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Action</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keys?.map((key: any, index: number) => {
                                if (key.status != Delete) {
                                    return (
                                        <TableRow key={key.api_key}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left">
                                                {key.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {key.api_key}
                                            </TableCell>
                                            <TableCell align="left">
                                                {key.status === Revoke
                                                    ? "Revoked"
                                                    : "Active"}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        updateKeyStatus(
                                                            key.api_key,
                                                            key.status ===
                                                                Active
                                                                ? Revoke
                                                                : Active
                                                        )
                                                    }
                                                >
                                                    {key.status === Active
                                                        ? "Revoke"
                                                        : "Unrevoke"}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() =>
                                                        handleOpenDialog(
                                                            key.api_key
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Wrapper>
            <Modal
                open={keymodal}
                onClose={() => setKeymodal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Create Key
                    </Typography>
                    <TextField
                        fullWidth
                        label="Add name to give to the key"
                        variant="outlined"
                        value={keyName}
                        onChange={handleKeyNameChange}
                        sx={{ mt: 2 }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            addKey();
                            console.log("Key Name:", keyName); // Process the keyName as needed
                            setKeymodal(false);
                            setKeyName("");
                        }}
                        sx={{ mt: 2 }}
                    >
                        Create Key
                    </Button>
                </Box>
            </Modal>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this API key? This
                        action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Wrapper = styled.div`
    background: #fff;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 26px;
    margin-bottom: 30px;
    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 20px;
    }
`;

export default KeysTable;
