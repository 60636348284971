import { useState } from "react";
import {
    createWeb3Modal,
    defaultConfig,
    useWeb3Modal,
    useWeb3ModalAccount,
    useDisconnect,
} from "@web3modal/ethers/react";
// 1. Get projectId
const projectId = "db2a16f517a52cce619d6460b79eeea4";

// 2. Set chains
const mainnet = {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
};
const pulseChain = {
    chainId: 369, // The Chain ID for PulseChain Testnet
    name: "PulseChain",
    currency: "PLS", // Assuming the currency symbol
    explorerUrl: "https://scan.pulsechain.com", // Mainnet explorer for PulseChain
    rpcUrl: "https://pulsechain-rpc.publicnode.com", // RPC URL for PulseChain Testnet
};

// 3. Create a metadata object
const metadata = {
    name: "My Website",
    description: "My Website description",
    url: "https://tetradashboard.netlify.app/", // origin must match your domain & subdomain
    icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: "...", // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [pulseChain],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const useWeb3 = () => {
    const { open, close } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    return { open, close, disconnect, address, isConnected };
};

export default useWeb3;
